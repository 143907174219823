import React from "react";
import { TravelsDispatchContext } from "../";
import tTravel from "../../../models/travel";

const useTravelsDispatch = () => {
  const dispatch = React.useContext(TravelsDispatchContext);

  if (!dispatch)
    throw Error("useTravelsDispatch must be used within TravelsStateContext");

  const addTravel = (travel: tTravel) => {
    dispatch({ type: "add travel", travel });
  };

  return { addTravel };
};

export default useTravelsDispatch;
