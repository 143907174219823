import React from "react";
import { Input, InputGroup, InputProps } from "rsuite";
import ColoredIcon, { iColoredIconProps } from "../../Icons/ColoredIcon";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import Flex from "../../uikit/Flex";

import styles from "./styles.module.css";

interface iProps extends InputProps {
  value: any;
  onChange(value: any): void;
  placeholder: string;
  error?: iTextProps;

  label?: iTextProps;
  gap?: number;
  addon?: string;
  button?: { onClick(): void; icon: iColoredIconProps };
  [key: string]: any;
}

const SimpleInput: React.FC<iProps> = ({
  value,
  onChange,
  label,
  gap = 8,
  placeholder,
  error,
  addon,
  button,
  ...props
}) => {
  return (
    <Flex column gap={`${gap}px`}>
      {label && (
        <InterTag
          {...{
            size: 14,
            color: "noytrall_secondary",
            style: { fontWeight: 500 },
            ...label,
          }}
        />
      )}
      <Flex column gap="4px">
        <InputGroup>
          <Input
            {...{ value, placeholder, ...props }}
            onChange={(value) => {
              onChange(value);
            }}
            className={styles.input}
          />
          {button && (
            <InputGroup.Button onClick={button.onClick}>
              <ColoredIcon {...{ ...button.icon }} />
            </InputGroup.Button>
          )}
          {addon && <InputGroup.Addon>{addon}</InputGroup.Addon>}
        </InputGroup>
        {error?.text && <InterTag {...{ size: 10, color: "red", ...error }} />}
      </Flex>
    </Flex>
  );
};

export default SimpleInput;
