import React from "react";
import PageWrapper from "../../components/PageWrapper";
import Flex from "../../components/uikit/Flex";
import TitledComponent from "../../components/TitledComponent";

import dashboard from "../../assets/dashboard.svg";

const DashboardWrapped: React.FC = () => {
  return (
    <Flex>
      <TitledComponent
        title={{ text: "Dashboard", color: "noytrall_secondary" }}
        description={{ text: "" }}
        icon={{ src: dashboard, color: "noytrall_secondary" }}
      />
    </Flex>
  );
};

const Dashboard: React.FC = () => {
  return (
    <PageWrapper>
      <DashboardWrapped />
    </PageWrapper>
  );
};

export default Dashboard;
