import React, { useReducer } from "react";
import { Modal, ModalProps, useToaster } from "rsuite";

import TitledComponent from "../../components/TitledComponent";

import add from "../../assets/add.svg";
import directions from "../../assets/directions.svg";
import ModalActionButtons from "../../components/Buttons/ModalActionButtons";
import SimpleInput from "../../components/RsuiteWrapper/SimpleInput";
import Flex from "../../components/uikit/Flex";
import axios from "axios";
import { notification } from "../../utils/notifications";
import { getErrorMessage } from "../../utils/httpResponses/others";
import useLoaderDispatch from "../../context/LoaderContext/hooks/useLoaderDispatch";
import useRoutesDispatch from "../../context/RoutesContext/hooks/useRoutesDispatch";

type tData = {
  name: string;
  origin: string;
  destination: string;
};

type tErrors = Record<keyof tData, string>;

interface iState {
  data: tData;
  errors: tErrors;
}

type tAction =
  | { type: "reset" }
  | { type: "set errors"; errors: Partial<tErrors> }
  | { type: "set data"; key: keyof tData; value: any };

const initialState: iState = {
  data: {
    name: "",
    origin: "",
    destination: "",
  },
  errors: {
    name: "",
    origin: "",
    destination: "",
  },
};

const reducer = (state: iState, action: tAction): iState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "set errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "set data": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iProps extends ModalProps {
  onClose(): void;
}

const NewRouteModal: React.FC<iProps> = ({ onClose, open, ...rest }) => {
  const { setLoader } = useLoaderDispatch();
  const toaster = useToaster();
  const { addRoute } = useRoutesDispatch();
  const [state, dispatch]: [iState, React.Dispatch<tAction>] = useReducer(
    reducer,
    initialState
  );

  const {
    data: { name, origin, destination },
    errors,
  } = state;

  const handleClose = () => {
    onClose();
    dispatch({ type: "reset" });
  };

  const handleCreate = () => {
    const errors: Partial<tErrors> = {};

    if (!name) errors["name"] = "Set a valid route name";
    if (!origin) errors["origin"] = "Set a valid route origin";
    if (!destination) errors["destination"] = "Set a valid route destination";

    if (Object.keys(errors).length)
      return dispatch({ type: "set errors", errors });

    setLoader(true);
    axios
      .post("/routes", { name, origin, destination })
      .then((res) => {
        const {
          data: { route },
        } = res;
        toaster.push(notification("success", "Route successfully created"), {
          placement: "topEnd",
        });
        addRoute(route);
        handleClose();
      })
      .catch((err) => {
        toaster.push(notification("error", getErrorMessage(err)), {
          placement: "topEnd",
        });
      })
      .finally(() => setLoader(false));
  };

  const setData = (key: keyof tData) => (value: any) =>
    dispatch({ type: "set data", key, value });

  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Header>
        <TitledComponent
          title={{ text: "Add Route" }}
          description={{ text: "Create a new route" }}
          icon={{ src: directions, color: "noytrall_secondary", size: 20 }}
          style={{ marginBottom: "12px" }}
        />
      </Modal.Header>
      <Modal.Body>
        <Flex column gap="16px">
          <div>
            <SimpleInput
              value={name}
              onChange={setData("name")}
              placeholder="John Doe"
              error={{ text: errors["name"] }}
              label={{ text: "Route name" }}
            />
          </div>
          <div>
            <SimpleInput
              value={origin}
              onChange={setData("origin")}
              placeholder="Madrid"
              error={{ text: errors["origin"] }}
              label={{ text: "Route origin" }}
            />
          </div>
          <div>
            <SimpleInput
              value={destination}
              onChange={setData("destination")}
              placeholder="Lisbon"
              error={{ text: errors["destination"] }}
              label={{ text: "Route Destination" }}
            />
          </div>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <ModalActionButtons
          text={{
            text: "DISCARD AND CLOSE",
            color: "primary",
            onClick: handleClose,
          }}
          button={{
            text: { text: "ADD THIS TRAVEL", color: "anti_flash_white" },
            icon: { src: add, color: "anti_flash_white" },
            ionColor: "primary",
            onClick: handleCreate,
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default NewRouteModal;
