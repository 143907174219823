import { IonText } from "@ionic/react";
import { forwardRef } from "react";

import styles from "./styles.module.css";

export interface iTextProps {
  text: any | any[];

  opacity?: number;
  rgba?: [number, number, number, number];
  color?: string;
  className?: string;
  style?: Record<string, string>;
  pTagStyle?: Record<string, string>;
  bold?: boolean;
  textAlign?: "justify" | "center";
  asHTML?: boolean;
  onHover?: { underline: boolean };
  tooltip?: string;
  [key: string]: any;
}

interface iTag extends iTextProps {
  font: "inter";
}

const TextTag = forwardRef<HTMLIonTextElement, iTag>(
  (
    {
      font,
      text,
      opacity = 1,
      color = "noytrall_secondary",
      rgba,
      className = "",
      style = {},
      pTagStyle = {},
      bold,
      textAlign,
      asHTML,
      onHover = { underline: false },
      ...props
    },
    ref
  ) => {
    const classname = `${className} ${
      onHover.underline ? styles.underline : ""
    }`;

    if (asHTML)
      return (
        <IonText
          ref={ref}
          className={classname}
          style={{
            height: "fit-content",
            fontWeight: bold ? "bold" : "normal",
            ...style,
            opacity,
          }}
          color={color}
          {...props}
        >
          <p
            className={font}
            style={{
              // userSelect: "text",

              ...(textAlign ? { textAlign } : {}),
              ...(rgba
                ? { color: `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${rgba[3]})` }
                : {}),
              ...pTagStyle,
            }}
            {...{
              ...(asHTML ? { dangerouslySetInnerHTML: { __html: text } } : {}),
            }}
          />
        </IonText>
      );

    return (
      <IonText
        ref={ref}
        className={classname}
        style={{
          height: "fit-content",
          fontWeight: bold ? "bold" : "normal",
          ...style,
          opacity,
        }}
        color={color}
        {...props}
      >
        <p
          className={font}
          style={{
            // userSelect: "text",

            ...(textAlign ? { textAlign } : {}),
            ...(rgba
              ? { color: `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${rgba[3]})` }
              : {}),
            ...pTagStyle,
          }}
        >
          {text}
        </p>
      </IonText>
    );
  }
);

export default TextTag;
