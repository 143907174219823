import React, { useReducer, useCallback } from "react";
import { nTravels } from "./interfaces";
import { FORCE_CONTEXT_RUN } from "../../utils/context";
import axios from "axios";
import { getErrorMessage } from "../../utils/httpResponses/others";
import useEffectSafe from "../../hooks/useEffectSafe";

const TravelsDispatchContext = React.createContext<
  nTravels.tDispatchContext | undefined
>(undefined);
TravelsDispatchContext.displayName = "TravelsDispatchContext";
const TravelsStateContext = React.createContext<
  nTravels.tStateContext | undefined
>(undefined);
TravelsStateContext.displayName = "TravelsStateContext";

const LS_DATA = "__travels__";

const initialState: nTravels.iState = {
  data: {
    travels: [],
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nTravels.iState,
  action: nTravels.tAction
): nTravels.iState => {
  switch (action.type) {
    case "add travel": {
      const { travel } = action;
      const data = { ...state.data, travels: [...state.data.travels, travel] };

      localStorage.setItem(LS_DATA, JSON.stringify(data));

      return { ...state, data, status: "resolved", error: null };
    }

    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, data, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const TravelsContextProvider: React.FC<nTravels.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [nTravels.iState, React.Dispatch<nTravels.tAction>] =
    useReducer(reducer, initialState);

  const run = useCallback(() => {
    console.log("[TRAVELS CONTEXT] --> RUNNING");
    dispatch({ type: "pending" });
    axios
      .get("/travels")
      .then((res) => {
        const {
          data: { travels },
        } = res;
        console.log("[TRAVELS CONTEXT] --> DONE");
        dispatch({ type: "set data", data: { travels } });
      })
      .catch((err) => {
        console.log("[TRAVELS CONTEXT] --> ERROR", getErrorMessage(err));
        dispatch({ type: "rejected", error: getErrorMessage(err) });
      });
  }, []);

  useEffectSafe(() => {
    const inStorage = localStorage.getItem(LS_DATA);
    if (!inStorage || FORCE_CONTEXT_RUN.TRAVELS) run();
    else {
      const parsed = JSON.parse(inStorage);
      dispatch({ type: "set data", data: parsed });
    }
  }, [run]);

  return (
    <TravelsStateContext.Provider value={state}>
      <TravelsDispatchContext.Provider value={dispatch}>
        {children}
      </TravelsDispatchContext.Provider>
    </TravelsStateContext.Provider>
  );
};

export { TravelsContextProvider, TravelsDispatchContext, TravelsStateContext };
