import React, { forwardRef } from "react";
import TextTag, { iTextProps } from "../";

import styles from "./styles.module.css";

export type tInterSize = number;

export interface iInterTagProps extends iTextProps {
  size: tInterSize;
}

const InterTag = forwardRef<HTMLIonTextElement, iInterTagProps>(
  ({ className = "", size, style, ...props }, ref) => {
    return (
      <TextTag
        {...props}
        ref={ref}
        style={{
          fontSize: `${size}px`,
          fontWeight: "normal",

          ...style,
        }}
        className={`${className} ${styles.tag} ${styles[`inter${size}`]}`}
        font="inter"
      />
    );
  }
);

export const Inter8: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <InterTag
      size={8}
      {...{
        className: `${className}`,
        ...rest,
      }}
    />
  );
};
export const Inter12: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <InterTag
      size={12}
      {...{
        className: `${className}`,
        ...rest,
      }}
    />
  );
};
export const Inter14: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <InterTag
      size={14}
      {...{
        className: `${className}`,
        ...rest,
      }}
    />
  );
};
export const Inter16: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <InterTag
      size={16}
      {...{
        className: `${className}`,
        ...rest,
      }}
    />
  );
};
export const Inter20: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <InterTag
      size={20}
      {...{
        className: `${className}`,
        ...rest,
      }}
    />
  );
};
export const Inter24: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <InterTag
      size={24}
      {...{
        className: `${className}`,
        ...rest,
      }}
    />
  );
};
export const Inter32: React.FC<iTextProps> = ({ className, ...rest }) => {
  return (
    <InterTag
      size={32}
      {...{
        className: `${className}`,
        ...rest,
      }}
    />
  );
};

export default InterTag;
