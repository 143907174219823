import React from "react";
import { InputGroup, InputNumber, InputNumberProps } from "rsuite";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import Flex from "../../uikit/Flex";

import styles from "./styles.module.css";

interface iProps extends InputNumberProps {
  value: number;
  onChange(value: any): void;

  error?: iTextProps;

  label?: iTextProps;
  subLabel?: iTextProps;
  gap?: number;

  addon?: string;
}

const SimpleInputNumber: React.FC<iProps> = ({
  value,
  onChange,
  label,
  subLabel,
  gap,
  error,
  addon,
  ...rest
}) => {
  return (
    <Flex one column gap={`${gap}px`}>
      {label && label.text && (
        <Flex column gap="4px">
          <InterTag
            {...{
              color: "noytrall_secondary",
              style: { fontWeight: 500 },
              size: 14,
              ...label,
            }}
          />
          {subLabel && subLabel.text && (
            <InterTag {...{ color: "gray4", size: 12, ...subLabel }} />
          )}
        </Flex>
      )}
      <Flex gap="4px" column>
        <InputGroup>
          <InputNumber
            className={styles.input}
            onChange={(value) => onChange(value)}
            {...{ value, ...rest }}
          />
          {addon && <InputGroup.Addon>{addon}</InputGroup.Addon>}
        </InputGroup>
        {error && <InterTag {...{ size: 10, color: "red", ...error }} />}
      </Flex>
    </Flex>
  );
};

export default SimpleInputNumber;
