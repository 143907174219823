import React from "react";
import { iTextProps } from "../Text";
import ColoredIcon, { iColoredIconProps } from "../Icons/ColoredIcon";
import Flex from "../uikit/Flex";
import InterTag from "../Text/Inter";

interface iProps {
  title: iTextProps;
  description?: iTextProps;
  icon?: iColoredIconProps;
  iconSlot?: "left" | "right";

  [key: string]: any;
}

const TitledComponent: React.FC<iProps> = ({
  title,
  description,
  icon,
  iconSlot = "right",
  style,
  ...rest
}) => {
  return (
    <Flex row between middle style={{ width: "100%", ...style }} {...rest}>
      <Flex column one>
        <Flex row gap="16px">
          {icon && (
            <Flex middle left>
              <ColoredIcon fontSize={"25px"} {...icon} />
            </Flex>
          )}
          <Flex>
            <InterTag color="noytrall_secondary" size={24} {...title} />
          </Flex>
        </Flex>
        {description && (
          <Flex row left style={{ marginTop: "8px" }}>
            <InterTag color="gray4" size={14} {...description} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default TitledComponent;
