import { isNull } from "lodash";
import moment from "moment";
import React from "react";
import { DateRangePicker, DateRangePickerProps } from "rsuite";
import ColoredIcon, { iColoredIconProps } from "../../Icons/ColoredIcon";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import Flex from "../../uikit/Flex";

import styles from "./styles.module.css";
import { iRange } from "./utils";

interface iProps {
  from: number | null;
  to: number | null;
  onSelect(values: number[] | null): void;
  label?: iTextProps;
  subLabel?: iTextProps;
  gap?: number;
  icon?: iColoredIconProps;
  appearance?: DateRangePickerProps["appearance"];
  className?: string;
  preventOverflow?: DateRangePickerProps["preventOverflow"];
  showOneCalendar?: DateRangePickerProps["showOneCalendar"];
  disabled?: DateRangePickerProps["disabled"];
  size?: DateRangePickerProps["size"];
  disabledDate?(date: Date): boolean;
  cleanable?: DateRangePickerProps["cleanable"];
  placeholder?: DateRangePickerProps["placeholder"];
  onClean?(e?: any): void;
  error?: iTextProps;
  ranges?: iRange[];
}

const SimpleDateRangePicker: React.FC<iProps> = ({
  label,
  subLabel,
  gap,
  icon,
  from,
  to,
  onSelect,
  appearance = "default",
  className,
  preventOverflow = true,
  showOneCalendar,
  disabled,
  size,
  disabledDate,
  cleanable = false,
  onClean,
  placeholder,
  error,
  ranges,
}) => {
  const render = () => {
    if (icon)
      return (
        <Flex center middle style={{ marginRight: "16px" }}>
          <ColoredIcon fontSize={18} color="light_white" {...icon} />
        </Flex>
      );
    return <></>;
  };

  const format = (date: moment.Moment | number | Date | string) => {
    return moment(date).format("MMM DD, YYYY");
  };

  const format2 = (
    date1: moment.Moment | number | Date | string,
    date2: moment.Moment | number | Date | string
  ) => {
    return `${format(date1)} - ${format(date2)}`;
  };

  return (
    <Flex column gap={`${gap}px`}>
      {label && (
        <Flex column gap="4px">
          <InterTag
            {...{
              size: 14,
              color: "noytrall_secondary",
              style: { fontWeight: 500 },
              ...label,
            }}
          />
          {subLabel && (
            <InterTag {...{ size: 12, color: "gray4", ...subLabel }} />
          )}
        </Flex>
      )}
      <Flex column gap="4px">
        <DateRangePicker
          className={`${className} ${styles.range}`}
          {...{
            ranges,
            preventOverflow,
            appearance,
            showOneCalendar,
            size,
            disabled,
            ...(icon ? { caretAs: render } : {}),
            disabledDate,
            cleanable,
            onClean,
            placeholder,
          }}
          editable={false}
          // caretAs={render}
          value={
            isNull(from) || isNull(to)
              ? null
              : [moment(from).toDate(), moment(to).toDate()]
          }
          renderValue={(value) => {
            const v0f = format(value[0]);
            const v1f = format(value[1]);

            const f = v0f === v1f ? v0f : format2(value[0], value[1]);
            if (ranges) {
              for (const { valueLabel, label } of ranges) {
                if (valueLabel === f) return `${label} (${f})`;
              }
            }

            return f;
          }}
          onChange={(value) => {
            // @ts-ignore
            onSelect(
              isNull(value) ? null : value.map((v) => moment(v).valueOf())
            );
          }}
          // @ts-ignore
        />

        {error && <InterTag {...{ size: 10, color: "red", ...error }} />}
      </Flex>
    </Flex>
  );
};

export default SimpleDateRangePicker;
