import React, { useReducer } from "react";
import { Modal, ModalProps, useToaster } from "rsuite";
import TitledComponent from "../../components/TitledComponent";

import add from "../../assets/add.svg";
import distance from "../../assets/distance.svg";
import ModalActionButtons from "../../components/Buttons/ModalActionButtons";
import SimpleDateRangePicker from "../../components/RsuiteWrapper/SimpleDateRangePicker";
import Flex from "../../components/uikit/Flex";
import SimpleInputNumber from "../../components/RsuiteWrapper/SimpleInputNumber";
import SimpleInput from "../../components/RsuiteWrapper/SimpleInput";

import axios from "axios";
import { notification } from "../../utils/notifications";
import { getErrorMessage } from "../../utils/httpResponses/others";
import useLoaderDispatch from "../../context/LoaderContext/hooks/useLoaderDispatch";
import useTravelsDispatch from "../../context/TravelsContext/hooks/useTravelsDispatch";

type tData = {
  period: { from: number | null; to: number | null };
  numberOfPeople: number;
  email: string;
};

type tErrors = Record<keyof tData, string>;

interface iState {
  data: tData;
  errors: tErrors;
}

type tAction =
  | { type: "reset" }
  | { type: "set errors"; errors: Partial<tErrors> }
  | { type: "set data"; key: keyof tData; value: any }
  | { type: "set period"; from: number | null; to: number | null };

const initialState: iState = {
  data: {
    period: { from: null, to: null },
    numberOfPeople: 0,
    email: "",
  },
  errors: {
    period: "",
    numberOfPeople: "",
    email: "",
  },
};

const reducer = (state: iState, action: tAction): iState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "set errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "set data": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    case "set period": {
      const { from, to } = action;
      return {
        ...state,
        data: { ...state.data, period: { from, to } },
        errors: { ...state.errors, period: "" },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iProps extends ModalProps {
  onClose(): void;
}

const NewTravelModal: React.FC<iProps> = ({ open, onClose, ...rest }) => {
  const { setLoader } = useLoaderDispatch();
  const toaster = useToaster();
  const { addTravel } = useTravelsDispatch();
  const [state, dispatch]: [iState, React.Dispatch<tAction>] = useReducer(
    reducer,
    initialState
  );

  const {
    data: {
      period: { from, to },
      numberOfPeople,
      email,
    },
    errors,
  } = state;

  const handleClose = () => {
    onClose();
    dispatch({ type: "reset" });
  };

  const handleCreate = () => {
    const errors: Partial<tErrors> = {};

    if (from === null || to === null) errors["period"] = "Set a valid period";
    if (numberOfPeople <= 0)
      errors["numberOfPeople"] = "Set a valid number, greater than 0";
    if (!email) errors["email"] = "Set a valid email";

    if (Object.keys(errors).length)
      return dispatch({ type: "set errors", errors });

    setLoader(true);
    axios
      .post(`/travels`, { from, to, numberOfPeople, email })
      .then((res) => {
        const {
          data: { travel },
        } = res;
        toaster.push(notification("success", "Travel successfully created"), {
          placement: "topEnd",
        });
        addTravel(travel);
        handleClose();
      })
      .catch((err) => {
        toaster.push(notification("error", getErrorMessage(err)), {
          placement: "topEnd",
        });
      })
      .finally(() => setLoader(false));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      //   overflow={false}
      //   keyboard={false}
      //   backdrop="static"
      //   {...{ ...rest }}
    >
      <Modal.Header>
        <TitledComponent
          title={{ text: "Add Travel" }}
          description={{ text: "Start planning a new travel" }}
          icon={{ src: distance, color: "noytrall_secondary", size: 20 }}
          style={{ marginBottom: "12px" }}
        />
      </Modal.Header>
      <Modal.Body>
        <Flex column gap="16px">
          <div>
            <SimpleDateRangePicker
              error={{ text: errors["period"] }}
              ranges={[]}
              label={{ text: "Travel Period" }}
              subLabel={{ text: "Set Arrival and Departure dates" }}
              gap={8}
              {...{ from, to }}
              onSelect={(values) => {
                if (!values)
                  dispatch({ type: "set period", from: null, to: null });
                else
                  dispatch({
                    type: "set period",
                    from: values[0],
                    to: values[1],
                  });
              }}
              placeholder="Select travel period"
            />
          </div>
          <div>
            <SimpleInput
              value={email}
              onChange={(value) =>
                dispatch({ type: "set data", key: "email", value })
              }
              placeholder="email@domain.com"
              error={{ text: errors["email"] }}
              label={{ text: "Email" }}
            />
          </div>
          <div>
            <SimpleInputNumber
              value={numberOfPeople}
              label={{ text: "Number of people" }}
              subLabel={{
                text: "Select the number of people participating in the travel",
              }}
              error={{ text: errors["numberOfPeople"] }}
              onChange={(value) =>
                dispatch({ type: "set data", key: "numberOfPeople", value })
              }
            />
          </div>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <ModalActionButtons
          text={{
            text: "DISCARD AND CLOSE",
            color: "primary",
            onClick: handleClose,
          }}
          button={{
            text: { text: "ADD THIS TRAVEL", color: "anti_flash_white" },
            icon: { src: add, color: "anti_flash_white" },
            ionColor: "primary",
            onClick: handleCreate,
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default NewTravelModal;
