import {
  IonContent,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { useLocation } from "react-router-dom";

import "./styles.css";
import Flex from "./../uikit/Flex";
import ColoredIcon from "./../Icons/ColoredIcon";

import dashboard from "../../assets/dashboard.svg";
import distance from "../../assets/distance.svg";
import directions from "../../assets/directions.svg";
import InterTag from "../Text/Inter";

interface AppPage {
  url: string;
  icon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "DASHBOARD",
    url: "/dashboard",
    icon: dashboard,
  },
  {
    title: "TRAVELS",
    url: "/travels",
    icon: distance,
  },
  {
    title: "ROUTES",
    url: "/routes",
    icon: directions,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay" color="noytrall_secondary">
      <IonContent color="noytrall_secondary">
        <IonList
          id="inbox-list"
          style={{
            backgroundColor: "var(--ion-color-noytrall_secondary)",
          }}
        >
          {appPages.map((appPage, index) => {
            const { icon } = appPage;
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                  color={
                    location.pathname === appPage.url
                      ? "primary"
                      : "noytrall_secondary"
                  }
                >
                  <Flex className="menu-item-icon" center middle>
                    <ColoredIcon src={icon} color="anti_flash_white" />
                  </Flex>
                  <Flex className="menu-item-label" middle>
                    <InterTag color="white" size={16} text={appPage.title} />
                  </Flex>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
