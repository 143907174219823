import React, { forwardRef } from "react";
import ColoredIcon, { iColoredIconProps } from "../../Icons/ColoredIcon";
import { iTextProps } from "../../Text";
import { Inter14 } from "../../Text/Inter";
import Flex from "../../uikit/Flex";

import styles from "./styles.module.css";

export interface iPrimaryButtonProps {
  id?: string;
  text: iTextProps;
  icon?: iColoredIconProps;
  iconSlot?: "left" | "right";
  ionColor?: string;
  color?: string;
  width?: number;
  height?: number;
  style?: Record<string, any>;
  onClick?(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  disabled?: boolean;
  [key: string]: any;
}

const PrimaryButton = forwardRef<any, iPrimaryButtonProps>(
  (
    {
      id,
      icon,
      text,
      iconSlot = "right",
      ionColor,
      color,
      width,
      height = 48,
      style,
      onClick,
      disabled = false,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        style={{
          borderRadius: "8px",
          ...(ionColor
            ? { backgroundColor: `var(--ion-color-${ionColor})` }
            : {}),
          ...(color ? { backgroundColor: color } : {}),
          ...(width ? { width: `${width}px` } : { width: "fit-content" }),
          ...(height ? { height: `${height}px` } : { height: "fit-content" }),
          paddingLeft: "24px",
          paddingRight: "24px",
          ...(disabled ? { opacity: 0.5 } : {}),
          ...style,
        }}
        id={id}
        className={`${styles.button} ${className || ""}`}
        {...props}
      >
        <Flex
          {...{
            ...(iconSlot === "right" ? { row: true } : { rowReverse: true }),
          }}
          gap="12px"
          middle
        >
          <Inter14 {...text} />
          {icon && <ColoredIcon {...icon} />}
        </Flex>
      </button>
    );
  }
);

export default PrimaryButton;
