import React, { Fragment, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import TitledComponent from "../../components/TitledComponent";
import distance from "../../assets/distance.svg";
import { iTableSegment } from "../../utils/interfaces/table";
import MainSegmentation from "../../components/MainSegmentation";
import Table, { tTableItem } from "../../components/Table";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

import add from "../../assets/add.svg";
import NewRouteModal from "./NewRouteModal";
import useRoutesState from "../../context/RoutesContext/hooks/useRoutesState";
import tRoute from "../../models/route";
import SimpleTextTableAttribute from "../../components/Table/TableAttributes/SimpleTextTableAttribute";
import SettingsTableItem from "../../components/Table/TableAttributes/SettingsTableItem";

const RoutesWrapped: React.FC = () => {
  const { routes } = useRoutesState();
  const [modal, setModal] = useState(false);

  const [activeSegment, setActiveSegment] = useState(0);

  const handleSelectSegment = (index: number) => {
    if (index === activeSegment) return;
    setActiveSegment(index);
  };

  const [active, inactive] = (() => {
    const active: tRoute[] = [];
    const inactive: tRoute[] = [];

    routes.forEach((route) => {
      if (route.active) active.push(route);
      else inactive.push(route);
    });
    return [active, inactive];
  })();

  const tableAttributes = [
    { name: "ROUTE", colSizeProportion: 0.3 },
    { name: "ORIGIN", colSizeProportion: 0.3 },
    { name: "DESTINATION", colSizeProportion: 0.3 },
    { name: "SETTINGS", colSizeProportion: 0.1, displayAttributeName: false },
  ];

  const tableItems = (list: tRoute[]): tTableItem[] => {
    return list.map((route) => {
      const { _id, name, origin, destination } = route;

      const id = `TABLE:ROUTES:${_id}`;

      return {
        id,
        values: {
          ROUTE: {
            element: <SimpleTextTableAttribute main={{ text: name }} />,
          },
          ORIGIN: {
            element: <SimpleTextTableAttribute main={{ text: origin.name }} />,
          },
          DESTINATION: {
            element: (
              <SimpleTextTableAttribute main={{ text: destination.name }} />
            ),
          },
          SETTINGS: {
            element: <SettingsTableItem />,
          },
        },
      };
    });
  };

  const views: iTableSegment[] = [
    {
      segment: { text: "ACTIVE", chip: active.length },
      table: {
        attributes: tableAttributes,
        items: tableItems(active),
      },
    },
    {
      segment: { text: "INACTIVE", chip: inactive.length },
      table: { attributes: tableAttributes, items: tableItems(inactive) },
    },
  ];

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Fragment>
      <NewRouteModal open={modal} onClose={handleCloseModal} />
      <div>
        <TitledComponent
          title={{ text: "Routes", color: "noytrall_secondary" }}
          description={{ text: "" }}
          icon={{ src: distance, color: "noytrall_secondary" }}
        />
        <div style={{ marginTop: "24px" }}>
          <PrimaryButton
            text={{ text: "ADD NEW ROUTE", color: "anti_flash_white" }}
            icon={{ src: add, color: "anti_flash_white" }}
            ionColor="primary"
            onClick={() => setModal(true)}
          />
        </div>
        <div style={{ marginTop: "32px" }}>
          <MainSegmentation
            activeSegment={activeSegment}
            onSelectSegment={handleSelectSegment}
            segments={views.map(({ segment }) => segment)}
          />
        </div>
        <div style={{ marginTop: "32px" }}>
          <Table
            attributes={views[activeSegment].table.attributes}
            items={views[activeSegment].table.items}
            id="ROUTES"
            isLoading={false}
            flexBasisDenominator={1}
            showNoItemsMessage
          />
        </div>
      </div>
    </Fragment>
  );
};

const Routes: React.FC = () => {
  return (
    <PageWrapper>
      <RoutesWrapped />
    </PageWrapper>
  );
};

export default Routes;
