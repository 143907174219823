import { IonIcon } from "@ionic/react";
import React from "react";

export interface iColoredIconProps {
  src: string;
  color?: any;
  size?: any;
  style?: any;
  stroke?: boolean;
  pointer?: boolean;
  onClick?(e: React.MouseEvent<HTMLIonIconElement, MouseEvent>): void;
  [key: string]: any;
}

const ColoredIcon: React.FC<iColoredIconProps> = ({
  src,
  color,
  size: fontSize,
  style,
  stroke,
  pointer,
  onClick,
  ...rest
}) => {
  return (
    <IonIcon
      src={src}
      style={{
        ...(color
          ? {
              fill: `var(--ion-color-${color})`,
              ...(stroke ? { stroke: `var(--ion-color-${color})` } : {}),
            }
          : {}),
        ...(fontSize ? { fontSize } : {}),
        ...(pointer || onClick ? { cursor: "pointer" } : {}),
        ...style,
      }}
      onClick={onClick}
      {...rest}
    />
  );
};

export default ColoredIcon;
