import React from "react";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import Flex from "../../uikit/Flex";

interface iProps {
  main: iTextProps;
  description?: iTextProps;
  [key: string]: any;
}

const SimpleTextTableAttribute: React.FC<iProps> = ({
  main,
  description,
  ...props
}) => {
  return (
    <Flex column {...props} gap="4px">
      <InterTag
        {...{
          size: 14,
          color: "noytrall_secondary",
          style: { wordBreak: "break-word" },
          ...main,
        }}
      />
      {description && (
        <InterTag
          {...{
            size: 14,
            color: "gray4",
            style: { wordBreak: "break-word" },
            ...description,
          }}
        />
      )}
    </Flex>
  );
};

export default SimpleTextTableAttribute;
