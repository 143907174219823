import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import Flex from "../uikit/Flex";

import { CONTENT_WIDTH } from "../../utils/dimensions";

interface iProps {
  children: any;
}

const TOTAL_WIDTH = CONTENT_WIDTH;
const MIN_MARGIN_HORIZONTAL = 16;
const WIDTH = TOTAL_WIDTH + 2 * MIN_MARGIN_HORIZONTAL;

const PageWrapper: React.FC<iProps> = ({ children }) => {
  return (
    <IonPage>
      <IonContent color="anti_flash_white">
        <Flex className="uk-flex-center" /*  style={{ overflowY: "auto" }} */>
          <div
            style={{
              maxWidth: `${WIDTH}px`,
              width: "100%",
              padding: `36px ${MIN_MARGIN_HORIZONTAL}px`,
            }}
          >
            {children}
          </div>
        </Flex>
      </IonContent>
    </IonPage>
  );
};

export default PageWrapper;
