import React from "react";
import { TravelsStateContext } from "../";

const useTravelsState = () => {
  const state = React.useContext(TravelsStateContext);

  if (!state)
    throw Error("useTravelsState must be used within TravelsStateContext");

  const {
    data: { travels },
    status,
  } = state;

  return { travels, isResolved: status === "resolved" };
};

export default useTravelsState;
