const DO_CONTEXT_IF_NOT_IN_STORAGE = (() => {
  if (window.location.hostname !== "localhost") return true;

  return false;
})();

export const FORCE_CONTEXT_RUN = {
  TRAVELS: true || (DO_CONTEXT_IF_NOT_IN_STORAGE && false),
  ROUTES: true || (DO_CONTEXT_IF_NOT_IN_STORAGE && false),
};

export const RESOLVED_STATUS = "resolved";
export const PENDING_STATUS = "pending";
export const IDLE_STATUS = "idle";
export const REJECTED_STATUS = "rejected";
