import { Notification, NotificationProps } from "rsuite";
import InterTag from "../components/Text/Inter";
import Flex from "../components/uikit/Flex";

export const notification = (
  type: NotificationProps["type"],
  message: string
) => {
  return (
    <Notification type={type} header={type} closable>
      <Flex>
        <InterTag size={14} text={message} />
      </Flex>
    </Notification>
  );
};
