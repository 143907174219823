import React, { Fragment, useReducer } from "react";
import Spinner from "../../components/Spinner";
import { nLoader } from "./interfaces";

const LoaderDispatchContext = React.createContext<
  nLoader.tDispatchContext | undefined
>(undefined);
LoaderDispatchContext.displayName = "LoaderDispatchContext";
const LoaderStateContext = React.createContext<
  nLoader.tStateContext | undefined
>(undefined);
LoaderStateContext.displayName = "LoaderStateContext";

const initialState: nLoader.iState = {
  isLoading: false,
};

const reducer = (
  state: nLoader.iState,
  action: nLoader.tAction
): nLoader.iState => {
  switch (action.type) {
    case "set": {
      const { isLoading } = action;
      return { ...state, isLoading };
    }
    default:
      return { ...state };
  }
};

const LoaderContextProvider: React.FC<nLoader.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [nLoader.iState, React.Dispatch<nLoader.tAction>] =
    useReducer(reducer, initialState);

  const { isLoading } = state;

  return (
    <LoaderStateContext.Provider value={state}>
      <LoaderDispatchContext.Provider value={dispatch}>
        <Fragment>
          <Spinner on={isLoading} />
          {children}
        </Fragment>
      </LoaderDispatchContext.Provider>
    </LoaderStateContext.Provider>
  );
};

export { LoaderContextProvider, LoaderDispatchContext, LoaderStateContext };
