import React from "react";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import Flex from "../../uikit/Flex";
import PrimaryButton, { iPrimaryButtonProps } from "../PrimaryButton";

interface iProps {
  text: iTextProps;
  button: iPrimaryButtonProps;

  gap?: number;
}

const ModalActionButtons: React.FC<iProps> = ({
  text,
  button: { icon, text: buttonText, ...button },
  gap = 32,
}) => {
  return (
    <Flex right middle gap={`${gap}px`}>
      <InterTag
        style={{ cursor: "pointer" }}
        onHover={{ underline: true }}
        size={14}
        color="primary"
        {...text}
      />
      <PrimaryButton
        {...{
          ionColor: "primary",
          ...(icon ? { icon: { color: "anti_flash_white", ...icon } } : {}),
          text: { color: "anti_flash_white", size: 14, ...buttonText },
          ...button,
        }}
      />
    </Flex>
  );
};

export default ModalActionButtons;
