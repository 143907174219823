import React from "react";

interface iProps {
  on: boolean;
}

const Spinner: React.FC<iProps> = ({ on }) => {
  if (!on) return <></>;

  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        zIndex: 100000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        data-uk-spinner="ratio: 3"
        style={{ color: "var(--ion-color-light_white)" }}
      />
    </div>
  );
};

export default Spinner;
