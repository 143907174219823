import { isNumber } from "lodash";
import React from "react";
import Flex from "../uikit/Flex";
import InterTag, { Inter14 } from "../Text/Inter";

export type tMainSegmentationSegment = {
  text: string;
  chip?: number;
};

interface iProps {
  activeSegment: number;
  segments: tMainSegmentationSegment[];
  onSelectSegment(index: number): void;
}

const MainSegmentation: React.FC<iProps> = ({
  activeSegment,
  onSelectSegment,
  segments,
}) => {
  return (
    <div style={{ overflowY: "hidden" }}>
      <div
        style={{
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <Flex row bottom>
          {segments.map((segment, i) => {
            const { text, chip } = segment;

            let textColor = "noytrall_secondary";
            let bottomBorderColor = "gray5";
            let chipColor = "gray5";
            let chipTextColor = "noytrall_secondary";
            if (i === activeSegment) {
              textColor = "primary";
              bottomBorderColor = "primary";
              chipColor = "primary";
              chipTextColor = "anti_flash_white";
            }

            return (
              <Flex
                key={text}
                row
                left
                middle
                style={{
                  borderBottom: `1px solid var(--ion-color-${bottomBorderColor})`,
                  cursor: "pointer",
                  height: "40px",

                  padding: `8px 48px 8px 8px`,
                }}
                onClick={() => {
                  if (activeSegment !== i) onSelectSegment(i);
                }}
              >
                <Inter14
                  text={text}
                  color={textColor}
                  style={{ whiteSpace: "nowrap" }}
                />

                {isNumber(chip) && (
                  <Flex
                    center
                    middle
                    style={{
                      borderRadius: "50%",
                      height: "32px",
                      width: "32px",
                      backgroundColor: `var(--ion-color-${chipColor})`,
                      marginLeft: "16px",
                    }}
                  >
                    <InterTag size={14} text={chip} color={chipTextColor} />
                  </Flex>
                )}
              </Flex>
            );
          })}
        </Flex>
      </div>
    </div>
  );
};

export default MainSegmentation;
