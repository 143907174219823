import { useEffect } from "react";

const useEffectSafe = (
  run: () => void,
  dependencies: any[],
  timeout: number = 300
) => {
  useEffect(() => {
    const t = setTimeout(() => {
      run();
    }, timeout);
    return () => {
      clearTimeout(t);
    };
  }, [...dependencies]);
};

export default useEffectSafe;
