import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import "./styles/global.css";
import "./styles/rsuite.css";

import Dashboard from "./pages/Dashboard";
import Travels from "./pages/Travels";
import Routes from "./pages/Routes";
import axios from "axios";
import { serverAddress } from "./utils/apiCall";
import { useEffect } from "react";
import { LoaderContextProvider } from "./context/LoaderContext";

setupIonicReact();

const AppWrapped: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane
          style={{
            "--side-min-width": "88px",
            "--side-max-width": "256px",
          }}
          contentId="main"
          when="(min-width: 640px)"
        >
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/dashboard" exact={true}>
              <Dashboard />
            </Route>
            <Route path="/travels" exact={true}>
              <Travels />
            </Route>
            <Route path="/routes" exact={true}>
              <Routes />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

const App: React.FC = () => {
  useEffect(() => {
    axios.defaults.baseURL = `${serverAddress()}/calculator`;
  }, []);
  return <AppWrapped />;
};

export default App;
