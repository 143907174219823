import React from "react";
import { RoutesDispatchContext } from "../";
import tRoute from "../../../models/route";

const useRoutesDispatch = () => {
  const dispatch = React.useContext(RoutesDispatchContext);

  if (!dispatch)
    throw Error("useRoutesDispatch must be used within RoutesStateContext");

  const addRoute = (route: tRoute) =>
    dispatch({ type: "add routes", routes: [route] });

  return { addRoute };
};

export default useRoutesDispatch;
