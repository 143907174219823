import React, { forwardRef } from "react";

type tProps = {
  row?: boolean;
  rowReverse?: boolean;
  column?: boolean;
  columnReverse?: boolean;

  // Horizontal Alignment
  left?: boolean;
  center?: boolean;
  right?: boolean;
  between?: boolean;
  around?: boolean;

  // Vertical Alignment
  stretch?: boolean;
  top?: boolean;
  middle?: boolean;
  bottom?: boolean;

  // Item dimensions
  none?: boolean;
  auto?: boolean;
  one?: boolean;

  // Wrap modifiers
  wrap?: boolean;
  wrapReverse?: boolean;
  nowrap?: boolean;

  gap?: string;

  basis?: number;

  color?: string;
  style?: Record<string, any>;
  id?: string;

  onMouseEnter?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onMouseLeave?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  onMouseOver?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;

  log?: boolean;

  [key: string]: any;
};

const Flex = forwardRef<HTMLDivElement, tProps>(
  (
    {
      row,
      rowReverse,
      column,
      columnReverse,

      // Horizontal Alignment
      left,
      center,
      right,
      between,
      around,

      // Vertical Alignment
      stretch,
      top,
      middle,
      bottom,

      none,
      auto,
      one,

      wrap,
      wrapReverse,
      nowrap,

      gap,
      basis,

      className,
      color,
      style,
      children,
      id,

      onMouseEnter,
      onMouseLeave,
      onMouseOver,

      log,

      ...props
    },
    ref
  ) => {
    const list = [
      { name: "row", prop: row },
      { name: "row-reverse", prop: rowReverse },
      { name: "column", prop: column },
      { name: "column-reverse", prop: columnReverse },
      { name: "left", prop: left },
      { name: "center", prop: center },
      { name: "right", prop: right },
      { name: "between", prop: between },
      { name: "around", prop: around },
      { name: "stretch", prop: stretch },
      { name: "top", prop: top },
      { name: "middle", prop: middle },
      { name: "bottom", prop: bottom },
      { name: "none", prop: none },
      { name: "auto", prop: auto },
      { name: "1", prop: one },
      { name: "wrap", prop: wrap },
      { name: "wrap-reverse", prop: wrapReverse },
      { name: "nowrap", prop: nowrap },
    ];
    const cn = list
      .filter(({ prop }) => prop)
      .map(({ name }) => `uk-flex-${name}`)
      .join(" ");

    if (log) {
      console.log(`FLEX#${id} -> ${color}`);
    }

    return (
      <div
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        style={{
          ...style,
          ...(gap ? { gap } : {}),
          ...(color ? { backgroundColor: `var(--ion-color-${color})` } : {}),
          ...(basis ? { flexBasis: `${basis}%` } : {}),
        }}
        className={`uk-flex ${cn} ${className || ""}`}
        id={id}
        {...props}
      >
        {children}
      </div>
    );
  }
);

export default Flex;
