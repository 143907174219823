import moment from "moment";
import React from "react";
import { Inter14 } from "../../Text/Inter";
import Flex from "../../uikit/Flex";

interface iProps {
  date: number;
  format?: string;
  afterFormat?(formatted: string): string;
}

const DateTableAttribute: React.FC<iProps> = ({
  date,
  format = "DD MMM YYYY",
  afterFormat = (formatted) => formatted,
}) => {
  const m = moment(date);

  let diffUnit = "day";
  let diff = Math.abs(m.diff(moment(), "days"));
  if (diff === 0) {
    diffUnit = "hour";
    diff = Math.abs(m.diff(moment(), "hours"));
  }
  if (diff === 0) {
    diffUnit = "minute";
    diff = Math.abs(m.diff(moment(), "minutes"));
  }
  let string = "";

  const dayString = `${diffUnit}${Math.abs(diff) === 1 ? "" : "s"}`;

  if (m.isBefore(moment())) string = `${diff} ${dayString} ago`;
  else string = `in ${diff} ${dayString}`;

  return (
    <Flex column gap="4px">
      <Inter14
        color="noytrall_secondary"
        text={afterFormat(m.format(format))}
      />
      <Inter14 color="gray4" text={string} />
    </Flex>
  );
};

export default DateTableAttribute;
