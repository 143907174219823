import React from "react";
import { RoutesStateContext } from "../";

const useRoutesState = () => {
  const state = React.useContext(RoutesStateContext);

  if (!state)
    throw Error("useRoutesState must be used within RoutesStateContext");

  const {
    data: { routes },
    status,
  } = state;

  return { routes, isResolved: status === "resolved" };
};

export default useRoutesState;
