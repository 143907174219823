import React, { useCallback } from "react";
import { LoaderDispatchContext } from "../";

const useLoaderDispatch = () => {
  const dispatch = React.useContext(LoaderDispatchContext);

  if (!dispatch)
    throw Error("useLoaderDispatch must be used within LoaderStateContext");

  const setLoader = useCallback(
    (value: boolean) => {
      dispatch({ type: "set", isLoading: value });
    },
    [dispatch]
  );

  return { setLoader };
};

export default useLoaderDispatch;
