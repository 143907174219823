// const IPV4 = "192.168.1.192";
const IPV4 = "localhost";

export const LOCAL_NODE_SERVER = `http://${IPV4}:5000`;
export const LOCAL_SERVERLESS_SERVER = `http://${IPV4}:3000`;
export const SERVERLESS_SERVER =
  "https://1x2be1smjf.execute-api.eu-west-1.amazonaws.com";

export const serverAddress = () => {
  if (window.location.hostname !== "localhost") return SERVERLESS_SERVER;

  return LOCAL_NODE_SERVER;
  // return SERVERLESS_SERVER;
};

export const fakeApiCall = (
  data: any,
  { timeout = 1000 }: { timeout?: number } = {}
): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return resolve(data);
    }, timeout);
  });
};
