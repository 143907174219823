import React, { useRef } from "react";
import ColoredIcon, { iColoredIconProps } from "../../Icons/ColoredIcon";
import Flex from "../../uikit/Flex";

import settings from "../../../assets/3-horizontal-dots.svg";
import { Dropdown } from "rsuite";
import InterTag from "../../Text/Inter";
import { iTextProps } from "../../Text";

interface iProps {
  options?: {
    icon?: iColoredIconProps;
    text: iTextProps;
    onClick(): any;
    disabled?: boolean;
    display?: boolean;
  }[];
}

const SettingsTableItem: React.FC<iProps> = ({ options = [] }) => {
  const dropdownRef = useRef(null);

  const renderButton = (props: any, ref: any) => {
    return (
      <Flex
        right
        one
        style={{
          paddingRight: "8px",
          width: "100%",
        }}
      >
        <button
          {...props}
          ref={ref}
          style={{
            height: "fit-content",
            width: "fit-content",
            padding: 0,
            margin: 0,
            background: "none",
          }}
        >
          <ColoredIcon pointer src={settings} />
        </button>
      </Flex>
    );
  };
  return (
    <Dropdown
      style={{ padding: 0, width: "100%" }}
      ref={dropdownRef}
      placement="leftStart"
      renderToggle={renderButton}
    >
      {options
        .filter(({ display }) => display !== false)
        .map(({ text, icon, onClick }, i) => (
          <Dropdown.Item
            style={{ padding: 0 }}
            key={text.text}
            onSelect={() => {
              onClick();
              if (dropdownRef.current) {
                // @ts-ignore
                // dropdownRef.current.close();
              }
            }}
          >
            <Flex column middle style={{ width: "184px", height: "41px" }}>
              {i ? (
                <div
                  style={{
                    width: "90%",
                    borderBottom: "1px solid var(--ion-color-gray5)",
                  }}
                />
              ) : (
                <></>
              )}
              <Flex middle style={{ width: "100%", height: "100%" }}>
                <Flex middle style={{ paddingLeft: "24px", width: "50px" }}>
                  {icon && <ColoredIcon {...{ ...icon }} />}
                </Flex>
                <Flex middle style={{}}>
                  <InterTag {...{ ...text }} />
                </Flex>
              </Flex>
            </Flex>
          </Dropdown.Item>
        ))}
    </Dropdown>
  );
};

export default SettingsTableItem;
