import React, { Fragment, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import TitledComponent from "../../components/TitledComponent";

import distance from "../../assets/distance.svg";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

import add from "../../assets/add.svg";
import { iTableSegment } from "../../utils/interfaces/table";
import MainSegmentation from "../../components/MainSegmentation";
import Table, { tTableItem } from "../../components/Table";
import useTravelsState from "../../context/TravelsContext/hooks/useTravelsState";
import tTravel from "../../models/travel";
import NewTravelModal from "./NewTravelModal";
import moment from "moment";
import SimpleTextTableAttribute from "../../components/Table/TableAttributes/SimpleTextTableAttribute";
import DateTableAttribute from "../../components/Table/TableAttributes/DateTableAttribute";
import SettingsTableItem from "../../components/Table/TableAttributes/SettingsTableItem";

const TravelsWrapped: React.FC = () => {
  const [modal, setModal] = useState(false);
  const { travels } = useTravelsState();

  const [activeSegment, setActiveSegment] = useState(0);
  const handleSelectSegment = (index: number) => {
    if (index === activeSegment) return;
    setActiveSegment(index);
  };

  const [plannedTravels, completedTravels, cancelledTravels] = (() => {
    const planned: tTravel[] = [];
    const done: tTravel[] = [];
    const cancelled: tTravel[] = [];

    travels.forEach((travel) => {
      switch (travel.status) {
        case "canceled":
          cancelled.push(travel);
          break;
        case "completed":
          done.push(travel);
          break;
        case "waiting":
          planned.push(travel);
          break;
        default:
          if (moment(travel.departure).isAfter(moment())) planned.push(travel);
          else done.push(travel);
          break;
      }
    });

    return [planned, done, cancelled];
  })();

  const tableAttributes = [
    { name: "ID", colSizeProportion: 0.3 },
    { name: "ARRIVAL", colSizeProportion: 0.25 },
    { name: "DEPARTURE", colSizeProportion: 0.25 },
    {
      name: "SETTINGS",
      displayAttributeName: false,
      colSizeProportion: 0.1,
    },
  ];

  const tableItems = (list: tTravel[]): tTableItem[] => {
    return list.map((travel) => {
      const { _id, departure, arrival } = travel;
      return {
        id: _id,
        values: {
          ID: { element: <SimpleTextTableAttribute main={{ text: _id }} /> },
          ARRIVAL: {
            element: <DateTableAttribute date={moment(arrival).valueOf()} />,
          },
          DEPARTURE: {
            element: <DateTableAttribute date={moment(departure).valueOf()} />,
          },
          SETTINGS: { element: <SettingsTableItem /> },
        },
      };
    });
  };

  const views: iTableSegment[] = [
    {
      segment: { text: "PLANNED", chip: plannedTravels.length },
      table: {
        attributes: tableAttributes,
        items: tableItems(plannedTravels),
      },
    },
    {
      segment: { text: "DONE", chip: completedTravels.length },
      table: {
        attributes: tableAttributes,
        items: tableItems(completedTravels),
      },
    },
    {
      segment: { text: "CANCELLED", chip: cancelledTravels.length },
      table: {
        attributes: tableAttributes,
        items: tableItems(cancelledTravels),
      },
    },
  ];

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <Fragment>
      <NewTravelModal open={modal} onClose={handleCloseModal} />
      <div>
        <TitledComponent
          title={{ text: "Travels", color: "noytrall_secondary" }}
          description={{ text: "" }}
          icon={{ src: distance, color: "noytrall_secondary", size: 20 }}
        />
        <div style={{ marginTop: "24px" }}>
          <PrimaryButton
            text={{ text: "ADD NEW TRAVEL", color: "anti_flash_white" }}
            icon={{ src: add, color: "anti_flash_white" }}
            ionColor="primary"
            onClick={() => setModal(true)}
          />
        </div>
        <div style={{ marginTop: "32px" }}>
          <MainSegmentation
            activeSegment={activeSegment}
            onSelectSegment={handleSelectSegment}
            segments={views.map(({ segment }) => segment)}
          />
        </div>
        <div style={{ marginTop: "32px" }}>
          <Table
            attributes={views[activeSegment].table.attributes}
            items={views[activeSegment].table.items}
            id="TRAVELS"
            isLoading={false}
            flexBasisDenominator={1}
            showNoItemsMessage
          />
        </div>
      </div>
    </Fragment>
  );
};

const Travels: React.FC = () => {
  return (
    <PageWrapper>
      <TravelsWrapped />
    </PageWrapper>
  );
};

export default Travels;
