import React, { useMemo } from "react";
import InterTag, { Inter14 } from "../Text/Inter";
import Flex from "../uikit/Flex";
import { CONTENT_WIDTH } from "../../utils/dimensions";

export type tTableAttribute = {
  colSizeProportion?: number;
  name: string;
  displayAttributeName?: boolean;
  orderBy?(a: any, b: any): -1 | 0 | 1;
};

export type tTableItem = {
  id: string;
  values: Record<
    string,
    { element: any | any[]; containerStyles?: Record<string, any> }
  >;
};

export interface iTableProps {
  attributes: tTableAttribute[];
  id: string;
  isLoading: boolean;
  items: tTableItem[];
  flexBasisDenominator?: number;
  noItemsMessage?: string;
  showNoItemsMessage?: boolean;
  tableWidth?: number;
}

const Table: React.FC<iTableProps> = ({
  id,
  attributes,
  items,
  isLoading,
  flexBasisDenominator = 1,
  noItemsMessage = "No data to show",
  showNoItemsMessage,
  tableWidth = CONTENT_WIDTH,
}) => {
  const attributesElement = useMemo(() => {
    return (
      <Flex
        row
        between
        style={{
          background: "var(--ion-color-anti_flash_white)",
          borderBottom: "1px solid var(--ion-color-gray5)",
          width: "100%",
          minHeight: "80px",
        }}
      >
        {attributes.map(
          ({ name, colSizeProportion, displayAttributeName = true }) => (
            <Flex
              key={`${id}-table-${name}`}
              middle
              left
              style={{
                ...(colSizeProportion
                  ? {
                      flexBasis: `${
                        (colSizeProportion / flexBasisDenominator) * 100
                      }%`,
                    }
                  : {}),
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
            >
              {displayAttributeName && name && (
                <Inter14
                  style={{ fontWeight: "bold" }}
                  text={name.toUpperCase()}
                  color="noytrall_secondary"
                />
              )}
            </Flex>
          )
        )}
      </Flex>
    );
  }, [attributes, id, flexBasisDenominator]);

  const itemsElement = useMemo(() => {
    return items.map((item) => {
      const { id } = item;
      return (
        <Flex
          row
          between
          key={id}
          id={id}
          style={{
            borderBottom: "1px solid var(--ion-color-gray5)",
            background: "var(--ion-color-white)",
            width: "100%",
            minHeight: "80px",
          }}
          // @ts-ignore
          onMouseEnter={(e) => {
            const div = document.getElementById(id);
            if (div) {
              // @ts-ignore
              div.style.background = "rgba(var(--ion-color-primary-rgba-7))";
            }
          }}
          // @ts-ignore
          onMouseLeave={(e) => {
            const div = document.getElementById(id);
            if (div) {
              // @ts-ignore
              div.style.background = "white";
            }
          }}
        >
          {attributes
            .filter(({ name }) => item.values.hasOwnProperty(name))
            .map((attr) => {
              const { name, colSizeProportion } = attr;
              const { element, containerStyles } = item.values[name];
              return (
                <Flex
                  key={name}
                  style={{
                    ...(colSizeProportion
                      ? {
                          flexBasis: `${
                            (colSizeProportion / flexBasisDenominator) * 100
                          }%`,
                        }
                      : {}),
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    ...containerStyles,
                  }}
                >
                  {element}
                </Flex>
              );
            })}
        </Flex>
      );
    });
  }, [attributes, items, flexBasisDenominator]);

  if (
    !isLoading &&
    showNoItemsMessage &&
    noItemsMessage &&
    itemsElement.length === 0
  ) {
    return (
      <Flex left one>
        <InterTag size={14} text="No data to show" />
      </Flex>
    );
  }

  return (
    <div style={{ overflowY: "hidden" }}>
      <div
        style={{
          // overflowY: "hidden",
          background: "var(--ion-color-white)",
          padding: "16px",
          borderRadius: "8px",
          minWidth: `${tableWidth}px`,
        }}
      >
        <div>{attributesElement}</div>
        <div>
          {isLoading ? (
            <Flex center middle style={{ padding: "24px 0" }}>
              <div data-uk-spinner></div>
            </Flex>
          ) : (
            <div>{itemsElement}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Table);
