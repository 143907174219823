import React, { useReducer, useCallback } from "react";
import { nRoutes } from "./interfaces";
import { FORCE_CONTEXT_RUN } from "../../utils/context";
import axios from "axios";
import { getErrorMessage } from "../../utils/httpResponses/others";
import useEffectSafe from "../../hooks/useEffectSafe";

const RoutesDispatchContext = React.createContext<
  nRoutes.tDispatchContext | undefined
>(undefined);
RoutesDispatchContext.displayName = "RoutesDispatchContext";
const RoutesStateContext = React.createContext<
  nRoutes.tStateContext | undefined
>(undefined);
RoutesStateContext.displayName = "RoutesStateContext";

const LS_DATA = "__routes__";

const initialState: nRoutes.iState = {
  data: {
    routes: [],
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nRoutes.iState,
  action: nRoutes.tAction
): nRoutes.iState => {
  switch (action.type) {
    case "add routes": {
      const { routes } = action;
      const data = { ...state.data, routes: [...state.data.routes, ...routes] };

      localStorage.setItem(LS_DATA, JSON.stringify(data));

      return { ...state, data, status: "resolved", error: null };
    }

    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, data, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const RoutesContextProvider: React.FC<nRoutes.iContextProps> = ({
  children,
}) => {
  const [state, dispatch]: [nRoutes.iState, React.Dispatch<nRoutes.tAction>] =
    useReducer(reducer, initialState);

  const run = useCallback(() => {
    console.log("[ROUTES CONTEXT] --> RUNNING");

    dispatch({ type: "pending" });
    axios
      .get("/routes")
      .then((res) => {
        const {
          data: { routes },
        } = res;
        console.log("[ROUTES CONTEXT] --> DONE");
        dispatch({ type: "set data", data: { routes } });
      })
      .catch((err) => {
        console.log("[ROUTES CONTEXT] --> ERROR", getErrorMessage(err));
        dispatch({ type: "rejected", error: getErrorMessage(err) });
      });
  }, []);

  useEffectSafe(() => {
    const inStorage = localStorage.getItem(LS_DATA);
    if (!inStorage || FORCE_CONTEXT_RUN.ROUTES) run();
    else {
      const parsed = JSON.parse(inStorage);
      dispatch({ type: "set data", data: parsed });
    }
  }, [run]);

  return (
    <RoutesStateContext.Provider value={state}>
      <RoutesDispatchContext.Provider value={dispatch}>
        {children}
      </RoutesDispatchContext.Provider>
    </RoutesStateContext.Provider>
  );
};

export { RoutesContextProvider, RoutesDispatchContext, RoutesStateContext };
